<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="确认" width="550px"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form" layout="vertical">
      <a-form-item v-for="(item, index) of formData" :key="index"  :label="item.label">

        <a-input-number v-if="item.type === 'input'" style='width:100%'  :max="Number(it.total_fee)" :min="0" v-decorator="[item.name, { rules: item.rules }]"/>
        <!-- <a-input-number  v-model="value" :min="1" :max="10" @change="onChange" /> -->

        <a-textarea v-if="item.type === 'textarea'" v-decorator="[item.name, { rules: item.rules }]" :rows="3"/>

        <a-radio-group v-if="item.type === 'radio'">
          <a-radio v-for="(d, index) of paymentTypes" :key="index" :value="d['payment_type']">
            {{d['filter_name']}}
          </a-radio>
        </a-radio-group>

        <a-select  v-if="item.type === 'select'" @change="handleChange" v-decorator="[item.name, { initialValue:item.initialValue,rules: item.rules }]">
          <a-select-option v-for="(d, index) of paymentTypes" :key="index" :value="d['payment_type']">
            {{d['filter_name']}}
          </a-select-option>
        </a-select>

      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import C_ITEMS from '@/utils/items'
import moment from 'moment'

const formData = [
  {
    name: 'refund_type',
    label: '退费方式',
    type: 'select',
    initialValue:'JSAPI',
    rules: [{ required: true, message: '请输入申请理由!' }],
  },
  {
    name: 'refund_total',
    label: '退款金额',
    type: 'input',
    // initialValue:this.total_fee,
    rules: [{ required: true, message: '请输入退款金额!' }],
  },
  {
    name: 'apply_reason',
    label: '申请理由',
    type: 'textarea',
    rules: [{ required: true, message: '请输入申请理由!' }],
  },
]

export default {
  name: 'RefundModal',
  inject: ['parent'],
  props: {
    it: Object,
    paymentTypes:Array
  },
  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      confirmLoading: false,
      visible: false,
      ModalTitle: '退款申请',
      formData,
      seleteItems: {},
      form: this.$form.createForm(this),
    }
  },

  async created() {
    this.visible = true
    await this.$nextTick()
    this.form.setFieldsValue({
      refund_type:this.it.trade_type,
      refund_total:this.it.total_fee-this.it.refund_fee
    })

  },
  beforeDestroy() {
    this.visible = false
  },

  methods: {
    handleChange(value) {
      console.log(`selected ${value}`)
    },
    async handleOk() {
      try {
        const { it, form } = this
        let params = await form.validateFields()
        if (params) {
          params.out_trade_no = it.out_trade_no
          console.log(params)
          this.confirmLoading=true
          await this.$store.dispatch('paymentRefundApplyAction', { data: params })
            .then(res=>{
              form.resetFields()
              this.$message.success('退款成功！！')
              this.parent.hideRefundModal(1)
            })
            .catch(err=>{
              this.confirmLoading=false
            })
        }
      } catch {
      }
			
    },
    handleCancel() {
      this.parent.hideRefundModal(0)
    }
  }
}
</script>
