<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>财务中心</a-breadcrumb-item>
      <a-breadcrumb-item>收款记录</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body">
      <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
        <a-tab-pane key="1">
          <span slot="tab">收款记录</span>
          <skjl v-if="activeKey === '1'"/>
        </a-tab-pane>
        <a-tab-pane key="2">
          <span slot="tab">退款记录</span>
          <tkjl v-if="activeKey === '2'"/>
        </a-tab-pane>
        <!-- <a-tab-pane key="3">
          <span slot="tab">支付查询</span>
        </a-tab-pane>
        <a-tab-pane key="4">
          <span slot="tab">退款查询</span>
        </a-tab-pane> -->
      </a-tabs>
    </div>
  </div>
</template>

<script>
import skjl from './skjl'
import tkjl from './tkjl'

export default {
  name: 'Daily',
  components: {
    skjl,
    tkjl
  },

  data() {
    return {
      activeKey: '1',
    }
  },
  methods: {
    changeTab(key) {
      this.activeKey = key
    }
  }
}
</script>
