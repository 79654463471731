<template>
  <a-modal title="订单查询" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="55.5%" :footer="null" :zIndex='4100'
    @ok="handleOk" @cancel="handleCancel">
        <div style="min-height:300px">
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <a-descriptions title="" bordered :column="2">
                <a-descriptions-item v-for="(item,key) in list" :key="key" :label="key">
                {{item}}
                </a-descriptions-item>
            </a-descriptions>
        </div>
  </a-modal>
</template>

<script>
    export default {
        name:'queryModal',
        inject: ['parent'],
        data() {
            return {
                visible: false,
                loading: false,
                confirmLoading:false,
                list:{}
            }
        },
        props: {
            item: String,
            isTab: Number
        },
        created () {
            this.visible = true
            this.getQuery()
        },
        methods: {
            getQuery() {
                this.loading = true
                this.$store.dispatch(this.isTab == 1?'paymentRefundQueryAction':'paymentReceiveQueryAction',{data:{out_trade_no:this.item}})
                .then(res=>{
                    this.list = res.data
                    this.loading = false
                })
            },
            handleOk(){},
            handleCancel(){
                this.parent.hideQueryModal()
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>