<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item v-if="canExcelexport">
            <a-button icon="export" @click="toExport" :loading="exportLoading">导出</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-range-picker style="width:240px" :ranges="rangesData" :disabled-date="disabledDate" allowClear v-model="searchData.trade_date" :placeholder="['开始时间', '结束时间']"/>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>学员名称/手机号</span>
              </template>
              <a-input v-model.trim="searchData.user_search" allowClear placeholder="学员名称/手机号" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>交易订单号</span>
              </template>
              <a-input v-model.trim="searchData.out_trade_no" allowClear placeholder="交易订单号" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-select style="width:200px" allowClear showSearch v-model="searchData.studio_id" placeholder="交易校区" :filterOption="filterOption">
              <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item v-if="canIndex">
            <a-button type="primary" html-type="submit" :loading="loading" @click="searchList">搜索</a-button>
          </a-form-item>
          <a-form-item>
            <a-popover placement="bottom" trigger="click" v-model="visible">
              <template slot="content">
                <div class="more-search">
                  <a-form layout='inline'>
                    <!-- <a-form-item class="block-line" label="来源渠道" >
                      <a-tree-select
                          v-model="searchData.channel_id"
                          :tree-data="parentChannels"
                          tree-checkable
                          allowClear
                          treeNodeFilterProp="title"
                          :maxTagCount='1'
                          :dropdownStyle="{zIndex:4200,maxHeight:'400px'}"
                          :show-checked-strategy="SHOW_ALL"
                          placeholder="请选择来源渠道"
                      />
                    </a-form-item> -->
                    <a-form-item class="block-line" label="支付方式" >
                      <a-select allowClear v-model="searchData.trade_type" placeholder="请选择">
                        <a-select-option v-for="(d, index) of paymentTypes" :key="index" :value="d['trade_type']">{{ d['filter_name'] }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="支付类型" >
                      <a-select allowClear v-model="searchData.type" placeholder="请选择">
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="支付状态" >
                      <a-select allowClear v-model="searchData.trade_status" placeholder="请选择">
                        <a-select-option v-for="(d, index) of paymentStatus" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-form>
                  <div class="more-search__buttons">
                    <a-button type="dashed" @click="closeSearch">取消</a-button>
                    <a-button @click="reset">重置</a-button>
                    <a-button v-if="canIndex" type="primary" @click="searchList">搜索</a-button>
                  </div>
                </div>
              </template>
              <a-button>更多<a-icon type="down" /></a-button>
            </a-popover>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="id"
        :columns="columns" :dataSource="list" :scroll="{ x: 1700 }">
        <template slot="index" slot-scope="text, record , index">
          <span v-if="index+1 == list.length">合计</span>
          <span v-else>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>
        <template slot="studentName" slot-scope="text, record, index">
          <span v-if="index+1 == list.length"></span>
          <LUser v-else :data="record"/>
        </template>

        <template slot="out_trade_no" slot-scope="text,record">
          <a href="javascript:;" v-if="record.refund_type==='微信支付'" @click="showQueryModal(text)">{{text}}</a>
          <p v-else>{{text}}</p>
        </template>

        <template slot="action" slot-scope="text,record">
          <a href="javascript:;" v-if="record.trade_type==='微信支付'" @click="showRefundModal(record)">退款</a>
        </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
    
    <refundModal v-if="refundVisible" :paymentTypes='paymentTypes' :it="modalData"/>
    <queryModal v-if="isQueryModal" :isTab="1" :item="modalData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index', fixed: 'left'},
  { title: '学员名称', width:200, dataIndex: 'student_name', key: 'name', fixed: 'left', scopedSlots: { customRender: 'studentName' } },
  { title: '手机号', dataIndex: 'cellphone', key: '1' },
  { title: '交易订单号', dataIndex: 'out_trade_no', key: '2' , scopedSlots: { customRender: 'out_trade_no' } },
  { title: '所属校区', dataIndex: 'studio_name', key: '3' },
  { title: '退款理由', dataIndex: 'apply_reason', key: '5' },
  { title: '退款方式', dataIndex: 'refund_type', key: 'refund_type' },
  { title: '退款金额', dataIndex: 'refund_total', key: '4' ,align:'right'},
  // { title: '退款状态', dataIndex: 'trade_status', key: '7' },
  // { title: '退费方式', dataIndex: 'trade_type', key: '8' },
  { title: '经办人', dataIndex: 'created_by', key: '9' ,align:'center'},
  { title: '退款时间', dataIndex: 'created_at', key: '10' },
]

import C_ITEMS from '@/utils/items'
import refundModal from './refundModal'
import queryModal from './queryModal'
import ranges from "@/common/mixins/ranges";
import authority from '@/common/mixins/authority'
import tableMixins from '@/common/mixins/table'
import moment from 'moment'

export default {
  name: 'skjl',
  provide() {
    return {
      parent: this
    }
  },

  components: {
    queryModal,
    refundModal
  },

  data() {
    return {
      loading: false,
      isQueryModal: false,
      exportLoading:false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      paymentStatus: C_ITEMS.paymentStatus,
      studios: [],
      consultants: [],
      channelsId:[],
      parentChannels: [],
      paymentTypes: [],

      searchData: {},

      visible: false,
      refundVisible: false,
      authType:['finance','daily'],
    }
  },
  mixins: [ ranges , authority , tableMixins ],
  created() {
    this.getStudio()
    this.getSourceChannel()
    this.getPaymentType()
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    closeSearch() {
      this.visible = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchStudioAction', {})
      this.studios = res.data
    },
    async getPaymentType() {
      let res = await this.$store.dispatch('searchPaymentTypeAction', {})
      this.paymentTypes = res.data
    },

    async getSourceChannel(parent_id) {
      let res = await this.$store.dispatch('searchSourceChannelCascaderAction', {})
      this.parentChannels = res.data
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'trade_date') {
            obj['start_date'] = searchData[k][0]?moment(searchData[k][0]).format('YYYY-MM-DD'):''
            obj['end_date'] = searchData[k][1]?moment(searchData[k][1]).format('YYYY-MM-DD'):''
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('paymentRefundAction', { data: this.searchParams })
      this.list = res.items
      this.list.push({
        id:-1,
        student_name:'',
        cellphone:'',
        studio_id:'',
        refund_total:res._total.refund_total,
        apply_reason:'',
        created_by:'',
        created_at:'',
      })
      this.pageParams = res._meta
      this.loading = false
    },

    showRefundModal(item) {
      this.modalData = item
      this.refundVisible = true
    },
    hideRefundModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.refundVisible = false
    },
    showQueryModal(val){
      this.modalData = val
      this.isQueryModal = true
    },
    hideQueryModal(){
      this.isQueryModal = false
    },
    async toExport(){
      this.exportLoading = true
      let res = await this.$store.dispatch('paymentRefundExportAction', { data: {search:this.searchParams.search} })
      const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
      if ('download' in document.createElement('a')) { 
          const link = document.createElement('a')
          link.download = `退款记录.${moment().format('YYYY.MM.DD')}.xlsx`
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href) 
          document.body.removeChild(link)
      } else { //其他浏览器
          navigator.msSaveBlob(blob, fileName)
      }
      this.exportLoading = false
    }
    
  }
}
</script>
