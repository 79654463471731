<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item v-if="canExcelexport">
            <a-button icon="export" @click="toExport" :loading="exportLoading">导出</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-range-picker style="width:240px" :ranges="rangesData" :disabled-date="disabledDate" allowClear v-model="searchData.trade_date" :placeholder="['开始时间', '结束时间']"/>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>学员名称/手机号/支付内容/交易订单号</span>
              </template>
              <a-input v-model.trim="searchData.user_search" allowClear placeholder="学员/手机/支付内容/订单号" style="width: 210px"/>
            </a-tooltip>
          </a-form-item>
          <!-- <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>交易订单号</span>
              </template>
              <a-input v-model.trim="searchData.out_trade_no" allowClear placeholder="交易" style="width: 160px"/>
            </a-tooltip>
          </a-form-item> -->
          <a-form-item>
            <a-select style="width:200px" allowClear showSearch v-model="searchData.studio_id" placeholder="交易校区" :filterOption="filterOption">
              <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item v-if="canIndex">
            <a-button type="primary" html-type="submit" :loading="loading" @click="searchList">搜索</a-button>
          </a-form-item>
          <a-form-item>
            <a-popover placement="bottom" trigger="click" v-model="visible">
              <template slot="content">
                <div class="more-search">
                  <a-form layout='inline'>
                    <!-- <a-form-item class="block-line" label="来源渠道" >
                        <a-tree-select
                            v-model="searchData.channel_id"
                            :tree-data="parentChannels"
                            tree-checkable
                            allowClear
                            treeNodeFilterProp="title"
                            :maxTagCount='1'
                            :dropdownStyle="{zIndex:4200,maxHeight:'400px'}"
                            :show-checked-strategy="SHOW_ALL"
                            placeholder="请选择来源渠道"
                        />
                    </a-form-item> -->
                    <a-form-item class="block-line" label="支付方式" >
                      <a-select v-model="searchData.trade_type" allowClear placeholder="请选择">
                        <a-select-option v-for="(d, index) of paymentTypes" :key="index" :value="d['payment_type']">{{ d['filter_name'] }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="支付类型" >
                      <a-select v-model="searchData.type" allowClear placeholder="请选择">
                        <a-select-option v-for="(d, index) of tradeTypes" :key="index" :value="d['trade_type']">{{ d['filter_name'] }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="支付状态" >
                      <a-select v-model="searchData.trade_status" allowClear placeholder="请选择">
                        <a-select-option v-for="(d, index) of paymentStatus" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-form>
                  <div class="more-search__buttons">
                    <a-button type="dashed" @click="closeSearch">取消</a-button>
                    <a-button @click="reset">重置</a-button>
                    <a-button v-if="canIndex" type="primary" @click="searchList">搜索</a-button>
                  </div>
                </div>
              </template>
              <a-button>更多<a-icon type="down" /></a-button>
            </a-popover>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="out_trade_no"
        :columns="columns" :dataSource="list" :scroll="{ x: 1300}" @change="handleChange">
        <template slot="index" slot-scope="text, record , index">
          <span v-if="index+1 == list.length">合计</span>
          <span v-else>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>
        <template slot="studentName" slot-scope="text, record, index">
          <span v-if="index+1 == list.length"></span>
          <LUser v-else :data="record"/>
        </template>
        <template slot="out_trade_no" slot-scope="text,record">
          <a href="javascript:;" v-if="record.trade_type_name==='微信支付' || record.trade_type_name==='支付宝'" @click="showQueryModal(text)">{{text}}</a>
          <a v-else>{{text}}</a>
        </template>
        <template slot="action" slot-scope="text,record">
          <a href="javascript:;" v-if="record.trade_type_name==='微信支付' && (Number(record.total_fee)>Number(record.refund_fee))" @click="showRefundModal(record)">退款</a>
        </template>
        <template slot="trade_status" slot-scope="text,record">
          {{text}}<em v-if="record.refund_fee>0" style="font-style: normal;"><br/>(已退{{record.refund_fee}})</em>
        </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()} 条`"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
      </a-pagination>
    </div>
    
    <refundModal v-if="refundVisible" :paymentTypes='paymentTypes' :it="modalData"/>
    <orderModal v-if="orderVisible && canRead" :item="modalData"/>
    <queryModal v-if="isQueryModal" :isTab="2" :item="modalData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index', fixed: 'left'},
  { title: '学员名称', width:'180px', dataIndex: 'student_name', key: '0', fixed: 'left', scopedSlots: { customRender: 'studentName' } },
  { title: '交易校区', width:'220px', dataIndex: 'studio_id', key: '2' ,ellipsis:true,sorter:()=>{}},
  { title: '交易订单号', width:'180px', dataIndex: 'out_trade_no', key: '1', scopedSlots: { customRender: 'out_trade_no' } },
  { title: '支付内容', dataIndex: 'title', key: '3', ellipsis: true},
  // { title: '来源渠道', dataIndex: 'source_channel_id', key: '4' },
  { title: '支付金额', width:'120px', dataIndex: 'total_fee', key: '5' ,align:'right'},
  { title: '支付方式', width:'100px', dataIndex: 'trade_type_name', key: '7' ,align:"center"},
  { title: '支付类型', width:'100px', dataIndex: 'type', key: 'type' ,align:"center"},
  { title: '支付时间', width:'180px', dataIndex: 'dateline', key: '6' ,sorter:()=>{}, align: 'right'},
  { title: '支付状态', width:'100px', dataIndex: 'trade_status', key: '8' , align: 'right', scopedSlots: { customRender: 'trade_status' }},
  { title: '操作', key: 'operation', align: 'right', width: 46, scopedSlots: { customRender: 'action' }}
]

import C_ITEMS from '@/utils/items'
import moment from 'moment'
import queryModal from './queryModal'
import refundModal from './refundModal'
import orderModal from './orderModal'
import ranges from "@/common/mixins/ranges"
import authority from '@/common/mixins/authority'
import tableMixins from '@/common/mixins/table'
// import moment from 'moment'

export default {
  name: 'skjl',
  provide() {
    return {
      parent: this
    }
  },

  components: {
    queryModal,
    refundModal,
    orderModal,
  },

  data() {
    return {
      loading: false,
      exportLoading:false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],

      paymentStatus: C_ITEMS.paymentStatus,
      studios: [],
      consultants: [],
      channelsId:[],
      parentChannels: [],
      paymentTypes: [],
      tradeTypes: [],

      searchData: {},

      visible: false,
      refundVisible: false,
      isQueryModal: false,
      orderVisible: false,
      authType:['finance','daily'],
    }
  },
  mixins: [ ranges , authority , tableMixins ],
  created() {
    this.getStudio()
    this.getSourceChannel()
    this.getPaymentType()
    this.getTradeType()
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    closeSearch() {
      this.visible = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchStudioAction', {})
      this.studios = res.data
    },
    async getPaymentType() {
      let res = await this.$store.dispatch('searchPaymentTypeAction', {})
      this.paymentTypes = res.data
    },
    async getTradeType() {
      let res = await this.$store.dispatch('searchTradeTypeAction', {})
      this.tradeTypes = res.data
    },

    async getSourceChannel(parent_id) {
      let res = await this.$store.dispatch('searchSourceChannelCascaderAction', {})
      this.parentChannels = res.data
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'trade_date') {
            obj['start_date'] = searchData[k][0]?moment(searchData[k][0]).format('YYYY-MM-DD'):''
            obj['end_date'] = searchData[k][1]?moment(searchData[k][1]).format('YYYY-MM-DD'):''
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('paymentReceiveAction', { data: this.searchParams })
      this.list = res.items
      this.list.push({
        student_name:'',
        studio_id:'',
        out_trade_no:'',
        title:'',
        total_fee:res._total.total_fee,
        trade_type_name:'',
        dateline:'',
        trade_status:'',
      })
      this.pageParams = res._meta
      this.loading = false
    },
    handleChange(pagination, filters, sorter) {
      console.log(sorter);
      if(sorter.order){
        if(sorter.order === "ascend"){
          this.searchParams['sort'] = `${sorter.field}`
        }else{
          this.searchParams['sort'] = `-${sorter.field}`
        }
      }else{
        this.searchParams['sort'] = ''
      }
      this.getList()
    },

    showRefundModal(item) {
      this.modalData = item
      this.refundVisible = true
    },
    hideRefundModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.refundVisible = false
    },

    showOrderModal(item) {
      this.modalData = item
      this.orderVisible = true
    },
    hideOrderModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.orderVisible = false
    },
    showQueryModal(val){
      this.modalData = val
      this.isQueryModal = true
    },
    hideQueryModal(){
      this.isQueryModal = false
    },
    async toExport(){
      this.exportLoading = true
      let exportData = {
          search:this.searchParams.search,
          sort:this.searchParams.sort
      }
      let res = await this.$store.dispatch('paymentReceiveExportAction', exportData)
      const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
      if ('download' in document.createElement('a')) { 
          const link = document.createElement('a')
          link.download = `收款记录.${moment().format('YYYY.MM.DD')}.xlsx`
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href) 
          document.body.removeChild(link)
      } else { //其他浏览器
          navigator.msSaveBlob(blob, fileName)
      }
      this.exportLoading = false
    }
  }
}
</script>
